export enum  IssueStatus {
    'New',
    'In_Progress',
    'Resolved',
    'Closed'
}

export enum  EIssueStatus {
    DangCho = 0,
    DangXuLy = 1,
    DaHoanThanh = 2,
    DaKetThuc = 3,
}
