import * as moment from 'moment';
export class CommonFunc {
    public static DateEqual(date1, date2) {
        if (!date1 || !date2) { return true; }
        if (date1.getFullYear() !== date2.getFullYear()) { return false; }
        if (date1.getMonth() !== date2.getMonth()) { return false; }
        if (date1.getDate() !== date2.getDate()) { return false; }
        return true;
    }

    private static diff_weeks(dt2, dt1) {
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7);
        return Math.abs(Math.round(diff));
    }

    // 0: same day
    // 1: same week
    // 2: same month
    public static DateCompare(type: number, date): boolean {
        if (type === -1) {return true; }
        date = new Date(date);
        const now = new Date();
        if (type === 0) {
            return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear() && date.getDate() === now.getDate();
        } else if (type === 1) {
            return this.diff_weeks(date, now) === 0;
        } else if (type === 2) {
            return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
        } else if (type === 3) {
          return Math.ceil((date.getMonth() + 1) / 3) === Math.ceil((now.getMonth() + 1) / 3)
            && date.getFullYear() === now.getFullYear();
        } else if (type === 4) {
          // yesterday
          const yesterday = new Date(Date.now() - 86400000);
          return date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth()
            && date.getFullYear() === yesterday.getFullYear();
        } else if (type === 5) {
          // last week
          const lastweek = new Date(Date.now() - 604800000);
          return date.getDate() === lastweek.getDate() && date.getMonth() === lastweek.getMonth()
            && date.getFullYear() === lastweek.getFullYear();
        } else if (type === 6) {
          // this year
          return date.getFullYear() === now.getFullYear();
        }
        return false;
    }
}
