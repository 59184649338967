export class Enum {
    public static readonly ListIssueType = [
        {
            key: -1,
            value_vn: 'Tất cả',
            tab: 'FILTER.ALL'
        },
        {
            key: 0,
            value_vn: 'Lỗi/ sự cố',
            tab: 'ISSUES.TYPE.2'
        },
        {
            key: 1,
            value_vn: 'Phàn nàn',
            tab: 'ISSUES.TYPE.3'
        },
        {
            key: 2,
            value_vn: 'Thắc mắc',
            tab: 'ISSUES.TYPE.4'
        }
        // {
        //     key: 0,
        //     value_vn: 'Công việc phát sinh',
        //     tab: 'ISSUES.TYPE.0'
        // },
        // {
        //     key: 1,
        //     value_vn: 'Công việc hàng ngày',
        //     tab: 'ISSUES.TYPE.1'
        // },
        // {
        //     key: 2,
        //     value_vn: 'Lỗi/sự cố',
        //     tab: 'ISSUES.TYPE.2'
        // },
        // {
        //     key: 3,
        //     value_vn: 'Phàn nàn',
        //     tab: 'ISSUES.TYPE.3'
        // },
        // {
        //     key: 4,
        //     value_vn: 'Thắc mắc',
        //     tab: 'ISSUES.TYPE.4'
        // }
    ];

    public static readonly ListTaskType = [
      {
        key: -1,
        value_vn: 'Tất cả',
        tab: 'FILTER.ALL'
      },
      {
        key: 0,
        value_vn: 'Phát sinh',
        tab: 'TASKSCHEDULE.TYPE.0'
      },
      {
        key: 1,
        value_vn: 'Hàng ngày',
        tab: 'TASKSCHEDULE.TYPE.1'
      },
      {
        key: 2,
        value_vn: 'Hàng tuần',
        tab: 'TASKSCHEDULE.TYPE.2'
      },
      {
        key: 3,
        value_vn: 'Hàng tháng',
        tab: 'TASKSCHEDULE.TYPE.3'
      }
    ];

    public static readonly IssueType = {
        0: 'Công việc phát sinh',
        1: 'Công việc hàng ngày',
        2: 'Lỗi sự cố',
        3: 'Phàn nàn',
        4: 'Thắc mắc'
    };

    public static readonly ListIssueStatus = [
        {
            key: -1,
            value_vn: 'Tất cả',
            tab: 'FILTER.ALL'
        },
        {
            key: 0,
            value_vn: 'Đang chờ',
            tab: 'FILTER.NEW'
        },
        {
            key: 1,
            value_vn: 'Đang xử lý',
            tab: 'FILTER.IN_PROGRESS'
        },
        {
            key: 2,
            value_vn: 'Đã hoàn thành',
            tab: 'FILTER.RESOLVED'
        },
        {
            key: 3,
            value_vn: 'Đã kết thúc',
            tab: 'FILTER.CLOSED'
        }
    ];

    public static readonly IssueStatus = {
        0: 'Đang chờ',
        1: 'Đang xử lý',
        2: 'Đã hoàn thành',
        3: 'Đã kết thúc'
    };



    public static readonly ListIssuePriority = [
        {
            key: -1,
            value_vn: 'Tất cả',
            tab: 'FILTER.ALL'
        },
        {
            key: 0,
            value_vn: 'Thấp',
            tab: 'ISSUES.PRIORITIES.LOW'
        },
        {
            key: 1,
            value_vn: 'Trung bình',
            tab: 'ISSUES.PRIORITIES.NORMAL'
        },
        {
            key: 2,
            value_vn: 'Cao',
            tab: 'ISSUES.PRIORITIES.HIGH'
        },
        {
            key: 3,
            value_vn: 'Khẩn cấp',
            tab: 'ISSUES.PRIORITIES.URGENT'
        },
        {
            key: 4,
            value_vn: 'Ngay lập tức',
            tab: 'ISSUES.PRIORITIES.IMMEDIATE'
        }
    ];

    public static readonly IssuePriority = {
        0: 'Thấp',
        1: 'Trung bình',
        2: 'Khẩn cấp',
        3: 'Ngay lập tức'
    };

    public static readonly ListEmployeeStatus = [
        {
            value: -1,
            label_vn: 'EMPLOYEES.EMPLOYEE_STATUS.ALL'
        },
        {
            value: 0,
            label_vn: 'EMPLOYEES.EMPLOYEE_STATUS.ACTIVE'
        },
        {
            value: 1,
            label_vn: 'EMPLOYEES.EMPLOYEE_STATUS.INACTIVE'
        },
        {
            value: 2,
            label_vn: 'EMPLOYEES.EMPLOYEE_STATUS.INVISIBLE'
        }
    ];

    public static readonly EmployeeStatus = {
        0: 'EMPLOYEES.EMPLOYEE_STATUS.ACTIVE',
        1: 'EMPLOYEES.EMPLOYEE_STATUS.INACTIVE',
        2: 'EMPLOYEES.EMPLOYEE_STATUS.INVISIBLE'
    };
}
